<template>
  <div>
    <title-bar :items="[{ title }]" />

    <div class="box">
      <b-tabs type="is-boxed">
        <b-tab-item label="Datos personales">
          <form-datos-personales></form-datos-personales>
        </b-tab-item>
        <b-tab-item label="Cuenta de usuario">
          <form-cuenta-usuario></form-cuenta-usuario>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import FormCuentaUsuario from '../components/FormCuentaUsuario.vue';
import FormDatosPersonales from '../components/FormDatosPersonales.vue';
export default {
  components: { FormDatosPersonales, FormCuentaUsuario },
  computed:{
    title(){
      return this.$route.meta.title || "";
    }
  }
};
</script>
